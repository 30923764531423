import React from "react"
import Typewriter from 'typewriter-effect';
import styled from 'styled-components';

import {
  FaTwitter as Twitter,
  FaGithub as Github,
  FaLinkedin as Linkedin,
  // FaInstagram as Insta,
  FaFilePdf as File,
  FaEnvelope as Mail,
} from "react-icons/fa"

export default function info() {
  return (
    <SpacedDiv className="container my-5">
      <div className="my-5"></div>
      <div className="display-3" style={{ color: "#FF8A00" }}>
        <span style = {{color : "white"}}>Hello, I'm</span> <br />
        Harsimar Singh.
      </div>
      <div className="h1 code mt-4 mb-3">async {"{"}</div>
      {/* <div className="text-muted mx-5 my-4 h3 text-justify info">
        A Passionate JS Developer, UI/UX Enthusiast, Automation Geek and Web
        Developer.
        <br />
      </div> */}
      <div className="text-muted mx-5 my-4 h3 text-justify info">
        <br />
        I can be classified as 
        <Typer><Typewriter
          options={{
            strings: [
              ' Passionate JS Developer.',
              ' UI/UX Enthusiast.',
              ' Automation Geek.',
              ' Web Developer.'
            ],
            autoStart: true,
            loop: true
          }}
        />
        </Typer>
        <br />
        <br />
      </div>
      <div className="h1 code mt-2 mb-3">{"}"}</div>
      <div className="h1 mt-5">
        <a className="mr-5 icon" target="_blank" rel="noopener noreferrer" href="https://twitter.com/simar1043">
          <Twitter />
        </a>
        {/* <a className="mr-5 icon" target="_blank" rel="noopener noreferrer" href="https://instagram.com/simar1043">
          <Insta />
        </a> */}
        <a className="mr-5 icon" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/harsimar">
          <Linkedin />
        </a>
        <a className="mr-5 icon" target="_blank" rel="noopener noreferrer" href="https://github.com/simar1043">
          <Github />
        </a>
        <a className="mr-5 icon" target="_blank" rel="noopener noreferrer" href="mailto://simar1043+portfolio@gmail.com">
          <Mail />
        </a>
        <a className="mr-5 icon" target="_blank" rel="noopener noreferrer" href="https://harsimarsingh.com/resume.pdf">
          <File />
        </a>
      </div>
    </SpacedDiv>
  )
}

const Typer = styled.div`

    display: inline-block;
    padding-left: 8px;
    color:rgb(255, 138, 0);
    font-size: xxx-large;
`;
const SpacedDiv = styled.div`
    margin-top: 8rem !important;
`;
